import prevArrow from "../Assets/SW_prevArrow.png";

function PrevPage() {
  return (
    <div className="prevPage">
      {/* <p>prev.</p> */}
      <img src={prevArrow} alt="" />
    </div>
  );
}
export default PrevPage;
