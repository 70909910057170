import nextArrow from "../Assets/SW_nextArrow.png";

function NextPage() {
  return (
    <div className="nextPage">
      {/* <p>next.</p> */}
      <img src={nextArrow} alt="" />
    </div>
  );
}
export default NextPage;
